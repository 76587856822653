
import React ,{useRef}from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './Formdata.css';
import image from "../assets/log.png";
import data from "../assets/image.png";





const environmentalTestsList = [
  'Vibration',
  'Shock',
  'Ship motion',
  'Acceleration',
  'Bump',
  'Tilt',
  'Low temperature',
  'High Temperature',
  'Burn in',
  'Damp Heat',
  'Thermal Shock',
  'Thermal Cycling',
  'Tropical Exposure',
  'Mould Growth',
  'Salt spray',
  'Drip Test',
  'Sand & Dust test (IPX5 &IPX6)',
  'Driving rain test(IP1X, IP2X, IP5X, IP6X,IP7X& IP8X)'
];
const noiseVibrationTestsList = [
  'Structure Borne Noise',
  'Air Borne Noise'
];
  const requesition_acceptedlist= [
  'Yes',
  'No',
  ];
const specifyList=[
  'As per standard requirement' ,
  'As per customer requirement',
  'No external physical abnormalities found in EUT',
  'EUT is in Switched on Condition',
  'Others',
 ];

const fluidFlowTestsList = [
  'Burst Test',
  'Pneumatic Test',
  'Dismantling & Reassembly',
  'Life Cycle Test',
  'Flow Coefficient Hydraulic Test',
  'Hydraulic Proof',
  'Other'
];
const record_of_change_requestlist=[
  'Yes',
  'No',
 ]
const decesion_rule_communicated_with_customerslist=[
  'Yes',

  'No'
  ];
   const uncertainity_statement_in_test_reportlist=[
    'Required',
  
    'Not Required'
    ];
    const  wheather_repeat_testlist= [
      'Yes',
      'No',
      ];
      const  statement_of_confirmitylist= [
        'Yes',
        'No',
        ];
  
function Formdata() {
  const location = useLocation();
  const formData = location.state;
  const pdfRef=useRef();

  const handlePrint = () => {
    window.print();
  };
  // Check if formData exists before accessing its properties
  if (!formData) {
    return <div>No form data available</div>;
  }
  // const selectedOption = document.querySelector('input[name="decesion_rule_communicated_with_customers"]:checked')?.value;
  // const selectedOptionmention = document.querySelector('input[name="statement_of_confirmity"]:checked')?.value;
  // const selectedOptionreport = document.querySelector('input[name="uncertainity_statement_in_test_report"]:checked')?.value;
  // const selectedOptionrequest = document.querySelector('input[name="record_of_change_request"]:checked')?.value; 
  // const selectedOptionaccepted= document.querySelector('input[name="requesition_accepted"]:checked')?.value;
  // const selectedOptionrepeat = document.querySelector('input[name="wheather_repeat_test"]:checked')?.value;

  const formattedDate = formData.issue_date
  ? formData.issue_date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  : '';
 
  const formattedrDate = formData.revise_date
  ? formData.revise_date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  : '';
  const formattedDated = formData.date
  ? formData.date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  : '';

  const formattedtd = formData. test_start_date
  ? formData. test_start_date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  : '';

  const formatteduut = formData.uut_receipt_date
  ? formData.uut_receipt_date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  : '';
  const formattedcom = formData.test_completion_date
  ? formData.test_completion_date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  : '';
 
  return (
  //   <div className="container">
  // <div className="form-container border-double">
  // <div className="form-data">
  <div>
  <li><Link to="/">BACK</Link></li>
  <div className="form-container">
  
      <div className="print-content">
  {/* // <div className="form-container"ref={pdfRef}> */}
      {/* <li><Link to="/">BACK</Link></li> */}
      <div className="dataform-">
     
  
<div class="image-container">
  <div class='im'>
    <img src={image} alt="image" class="responsive-image" width="100%" height="130%" />
  </div>
  <div className='image-container1'>
<h3>DSRI LABS PVT.LTD.</h3>
<h4>TEST REQUISITION</h4>

</div>

     
<div className="item">

     <strong>Form No:</strong>DSRI/F/62<br></br>
     <strong>IssueNo:</strong> {formData.issuenumber}<br></br>
     {/* <strong>IssueDate:</strong> {formData.issue_date}<br></br> */}
     <strong>IssueDate:</strong>{formattedDate}
      {/* {formData.issue_date ? formData.issue_date.toDateString() : 'No date selected'} */}
      <br />
        <strong>RevNo:</strong> {formData.revise_no}  <br></br>
      
       
        <strong>RevDate:</strong> {formattedrDate}
     

</div>



    



  </div>
  
</div>
<div className='controlno'>
<ul>
<strong>CONTROL NO:</strong><li> {formData.controlnumber}</li>
<strong>DATE:</strong> <li>{formattedDated}</li>
{/* <li> {formData.date}</li> */}


<strong>UID NO:</strong> <li>{formData.uid}</li>
{/* <li><strong></strong> {}</li> */}

 </ul>
   
</div>
<div className='formdata3'>
   <div className='formdata2'>
   <h4 className="select-tests-headerh4">(TO BE FILLED BY CUSTOMER)</h4>
      <ul>
      <strong>Project</strong> <li>{formData.project}</li>
      <strong>Unit Under Test(UUT):</strong> <li>{formData.Unit_Under_Test}</li>
        <strong>Sl.No:</strong> <li>{formData.si_no}</li>
        <strong>Quantity:</strong>  <li>{formData.Qty}</li>
        <strong>Unit Weight:</strong>  <li> {formData.unit_weight}</li>
        <strong>Kg Fixture Weight:</strong>  <li> {formData.fixture_weight}</li>
        <strong>kg Contact Person:</strong>  <li>{formData.contact_person}</li>
        <strong>Phone Number:</strong> <li> {formData.mobile_no}</li>
        <strong>Company Name:</strong> <li>{formData.company_name}</li>
        <li><strong></strong> {}</li>
      
   
        </ul>
        </div>  
    <h2 className="select-tests-headerh4"> NAME OF TEST</h2>
    
{/* rdfgvhbknlkmkoi-u08yt86d6rcfgvjbhknkml */}
{formData.environment_test && formData.environment_test.length > 0 && (
  <div className='en'>
    <strong>ENVIRONMENTAL TEST:</strong>
  
      
      {environmentalTestsList.map((test, index) => (
        formData.environment_test.includes(test) && (
          <li key={test}>
            <span className="">  </span>
            <span>{test}</span>
            {index !== environmentalTestsList.length - 1 && <>&nbsp;</>}
          </li>
        )
      ))}
   
   
  </div>
)} 

{formData.noise_vibration_measurement_tests && formData.noise_vibration_measurement_tests.length > 0 && (
  <div className='en'> 
    <strong>NOISE & VIBRATION MEASUREMENT TEST:</strong>

      {noiseVibrationTestsList.map((test, index) => (
        formData.noise_vibration_measurement_tests.includes(test) && (
          <li key={test}>
            <span className="">  </span>
            <span>{test}</span>
            {index !== noiseVibrationTestsList.length - 1 && <>&nbsp;</>}
          </li>
        )
      ))}

  </div>
)}

{formData.fluid_flow_test && formData.fluid_flow_test.length > 0 && (
  <div className='en'>
    <strong>FLUID FLOW TEST:</strong>
  
      {fluidFlowTestsList.map((test, index) => (
        formData.fluid_flow_test.includes(test) && (
          <li key={test}>
            {/* <span className="tick-mark">&#10003;</span> */}
         
            <span>{test}</span>
            {index !== fluidFlowTestsList.length - 1 && <>&nbsp;</>}
          </li>
        )
      ))}
 
  </div>
)} 



<div>
       <p className='resh'><strong>Test Standards :</strong> {formData.test_standards}</p>
       </div>

<div>
       <p className='resh'><strong>Test Specifications:</strong> {formData.test_specifications}</p>
       </div>



{/* 
      <h3>Uncertainty Statement to be stated in Test Report:</h3> */}
<div className='str'>
  <strong>Uncertainty Statement to be stated in Test Report:</strong>
  {uncertainity_statement_in_test_reportlist
    .filter(test => formData.uncertainity_statement_in_test_report.includes(test))
    .map((test, index, array) => (
      <li key={test}> 
        {formData.uncertainity_statement_in_test_report && (
          <span className="">   </span>
        )}
        <span>{test}</span>
        {index !== array.length - 1 && <>&nbsp;/&nbsp;</>}
      </li>
    ))}
 
</div>

<div className='str'>
  <strong>Mention the Statement of conformity :</strong> {statement_of_confirmitylist
    .filter(test => formData.statement_of_confirmity && formData.statement_of_confirmity.includes(test))
    .map(test => (
      <span key={test}>
        {/* <span className="">&#10003;</span> */}
        {test}
      </span>
    ))}
 
</div>




{/* <div>
      <h3>Mention the Statement of conformity :</h3>
      <ul>
        <li>
          {(selectedOptionmention === 'Yes') ? (
            <span>(<span className="green">&#10003;</span> Yes<>&nbsp;/&nbsp;</>No)</span>
          ) : (
            <span>(Yes/<span className="green">&#10003;</span> No)</span>
          )}
        </li>
      </ul>
    </div> */}


      {/* <h3>Decision Rule (Communicated to and agreed with the customer):</h3> */}
      <div className='str'>
<strong>Decision Rule (Communicated to and agreed with the customer):</strong>
 
    {decesion_rule_communicated_with_customerslist
      .filter(test => formData.decesion_rule_communicated_with_customers && formData.decesion_rule_communicated_with_customers.includes(test))
      .map(test => (
        <span key={test}>
          {formData.decesion_rule_communicated_with_customers && formData.decesion_rule_communicated_with_customers.includes(test) && (
             <span className="">  </span>
          )}
          {test}
        </span>
      ))}

</div>


<img src={data} alt="image" class="responsive-image" width="100%" height="80%" />
  
<ul className="case" style={{ lineHeight: '0' }}>
  {formData.conformance.trim() === 'Case a' && (
 
      <span className="tick-markc">  Case a : Conformance</span>
      
   
  )}
  {formData.conformance.trim() === 'Case b' && (
  
      <span className="tick-markc">Case b : Conditional Conformance</span>
    
  
  )}
  {formData.conformance.trim() === 'Case c' && (

      <span className="tick-markc">Case c : Conditional non-Conformance</span>
     
   
  )}
  {formData.conformance.trim() === 'Case d' && (
  
      <span className="tick-markc">  Case d : non-Conformance</span>
  

  )}
</ul>

{/* <ul className="case">
 
    {formData.conformance.trim() === 'Case a' && (
      <span className="tick-mark">&#10003;</span>
    )}
    Case a : Conformance
  

    {formData.conformance.trim() === 'Case b' && (
      <span className="tick-mark">&#10003;</span>
    )}
    Case b : Conditional Conformance
 

 
    {formData.conformance.trim() === 'Case c' && (
      <span className="tick-mark">&#10003;</span>
    )}
    Case c : Conditional non-Conformance
 
 
    {formData.conformance.trim() === 'Case d' && (
      <span className="tick-mark">&#10003;</span>
    )}
    Case d : non-Conformance
 
</ul> */}


{/* <h5> If Conformity statement required the customer has to specify in the test request.</h5> */}

  {/* <ul>
    { statement_of_confirmitylist.map((test, index) => (
      <li key={test}> 
        {formData. statement_of_confirmity && formData. statement_of_confirmity.includes(test) && (
           <span className="tick-mark">&#10003;</span>
        )}
        <span>{test}</span>
        {index !==  statement_of_confirmitylist.length - 1 && <>&nbsp;/&nbsp;</>}
      </li>
    ))}
  </ul>
</div> */}
<div className='str'>
  <strong>If Conformity statement required the customer has to specify in the test request  :   </strong>

      {specifyList
        .filter(test => formData.specify && formData.specify.includes(test))
        .map((test, index) => (
          <li key={test}>
            <span className="">  </span>
            <span>{test}</span>
            {index !== specifyList.length - 1 && <>&nbsp;&nbsp;</>}
          </li>
        ))}
 
  
</div>






      {/* <h3>Record of Change Request(s)(if any):</h3> */}
<div className='str'>
  <strong>Record of Change Request(s)(if any):</strong>
  
    {record_of_change_requestlist
      .filter(test => formData.record_of_change_request && formData.record_of_change_request.includes(test))
      .map((test, index) => (
        <li key={test}> 
          {formData.record_of_change_request && formData.record_of_change_request.includes(test) && (
             <span className="">  </span>
          )}
          <span>{test}</span>
          {index !== record_of_change_requestlist.length - 1 && <>&nbsp;</>}
        </li>
      ))}

</div>


     
    <div className='str'>
       <p className='re'><strong>If Yes Pls. Specify:</strong> {formData.if_yes_pls_specify}</p>
       </div>
    <div className='str'>
    <strong>Requested By:</strong> {formData.requested_by}
    </div>
    <div className='signature'>
      <div>  <h3>Signature(Inspected By)</h3> 
       
    <h3><strong>Name:</strong> {formData.inspectedBySignature}</h3>
    </div>
    <div>
        <h3>Signature(Customer)</h3>  
       
    <h3><strong>Name:</strong> {formData.customerSignature}</h3>
    </div>
     
       </div>

       </div>
       <div className='formdata3d'>
       <div className='formdata2d'>
  
       <h4 className="select-tests-headerh4">(TO BE FILLED BY DSRI) </h4>
      <ul>
      <strong>Test Start Date:</strong> <li>{formattedtd}</li>
      {/* <li>{formData.test_start_date}</li> */}
      <strong>UUT Receipt Date:</strong> <li>{formatteduut}</li>
        {/* {formData.uut_receipt_date} */}
        <strong>Completion Date:</strong><li>{formattedcom}</li>
         {/* <li>{formData.test_completion_date}</li> */}
        <strong>Test Equipment:</strong>  <li> {formData.test_equipment}</li>
        <strong>Total Time of Equipment Use:</strong>  <li>{formData.total_time_of_equipment_use}</li>
        {/* <strong>Requisition Accepted</strong>  <li>{formData.Qty}</li> */}
       
      {/* <h3>Requisition Accepted :</h3> */}


    <div className='whether'>
  <strong>Requisition Accepted :</strong>

  {requesition_acceptedlist.map((test, index) => (
    <React.Fragment key={test}>
      {formData.requesition_accepted && formData.requesition_accepted.includes(test) && (
        <li> 
          <span className="">  </span>
          <span>{test}</span>
        </li>
      )}
      {index !== requesition_acceptedlist.length - 1 && (
        <React.Fragment>&nbsp;</React.Fragment>
      )}
    </React.Fragment>
  ))}
</div> 

{/* 
<div className='str'>
  <strong>Record of Change Request(s)(if any):</strong>
  
    {requesition_acceptedlist
      .filter(test => formData.requesition_accepted  && formData.requesition_accepted .includes(test))
      .map((test, index) => (
        <li key={test}> 
          {formData.requesition_accepted  && formData.requesition_accepted .includes(test) && (
             <span className="tick-mark">&#10003;</span>
          )}
          <span>{test}</span>
          {index !== requesition_acceptedlist.length - 1 && <>&nbsp;</>}
        </li>
      ))}

</div> */}




    
    
      {/* <h3>Whether Repeat test :</h3> */}
      <div className='whether'>
  <strong>Whether Repeat test :</strong>

  {wheather_repeat_testlist.map((test, index) => (
    <React.Fragment key={test}>
      {formData.wheather_repeat_test && formData.wheather_repeat_test.includes(test) && (
        <li> 
          <span className="">  </span>
          <span>{test}</span>
        </li>
      )}
    </React.Fragment>
  ))}
</div>


        {/* <strong>Whether Repeat test:</strong>  <li> {formData.fixture_weight}</li> */}
      
        {/* <strong>Remarks:</strong> <li> {formData.remarks}</li> */}
        {/* <div>
 <h4> Remarks :</h4>
  <p>{formData.remarks}</p>
</div> */}
<div className='str'>
    <strong>Remarks :</strong> {formData.remarks}
    </div>
        {/* <strong>Technical Manager:</strong> <li>{formData.company_name}</li> */}
        <h5 className='tm'>Technical Manager</h5>
        <li><strong></strong> {}</li>
      
   
        </ul>
        </div> 
        </div> 




        
       <div className='formdata2dl'>
       <h4 className="select-tests-headerh4">TO FILLED AFTER COMPLETION OF TEST </h4>
   
      <strong>Unit condition(pass/fail)</strong> <li>{formData.unit_condition}</li>
      
      
        {/* <div>
 <h5 > Remarks If Any :</h5>
  <p>{formData.remarks_if_any}</p>
</div> */}
<div className='str'>
    <strong>Remarks If Any :</strong> {formData.remarks_if_any}
    </div>
        {/* <strong>Technical Manager:</strong> <li>{formData.company_name}</li> */}
        <h4 className='tms'>Signature (Customer)</h4>
        <h4 className='tnd'>Name & Date:</h4>
        <li><strong></strong> {}</li>
      
   
      
        </div> 
        <div className='formdata3dt'>
        <h6 className="select-tests-headerh4">TERMS AND CONDITIONS</h6>
       <p>1.	The testing facility at the Laboratory is available for equipment/products/samples development and document (Test reports) as per the customer’s requirements.</p> 
       <p>2.	The terms and conditions for testing at this laboratory including the responsibilities of the customers in getting their equipment/products/samples to be tested, services to be rendered by the Testing laboratory.</p> 
       <p>3.	The customer who desires his equipment/products/samples to be tested, shall make a request in the writing to the laboratory giving adequate details of the equipment/products/samples to be tested, standards to be followed and the details of the tests to be conducted along with referred date of testing.</p> 
       <p>4.	The Testing Authority shall provide the customer program of testing. In case of suspension, postponement or cancellation of the tests due to outage of the laboratory, the Testing Authority will not, however, in anyway be liable for the losses that may be incurred by the customer due to delays or postponement resulting from such outage.</p> 
       <p>5.	The customer shall arrange at his own cost to transport and receive at the laboratory their equipment/product/samples at least one day before the date of commencement of the test. The customer is responsible for the handling of his equipment/products/samples.</p> 
       <p>6.	The customer is responsible for arranging fixtures, if any required for mounting his equipment/products in the test bay. The customer shall observe safety instructions in the test bays while installation of equipment/product/samples and dismantling.</p> 
       <p>7.	The customer shall indicate while filling up the Customer Requisition Form whether the equipment/product/samples submitted for testing will be taken back after testing or will be left behind. Customer to remove from DSRI premises such equipment/product/samples within 07 days after completion of testing, after 07 charges applied.</p> 
       <p>8.	The DSRI ET Lab issue the TEST REPORT at no extra cost, but only upon payment of the final bill of test charges, when an
equipment/product/sample of a particular type and rating, in the following situations:</p> 
<p>•	In case of failure in any one of the tests in the test sequence in compliance with the conditions stipulated in a published
National/International Standards. In case all the tests stipulated in compliance with published National/International Standards are not carried out in DS
</p>
          </div>
          <div className='formdata3dl'>
            <h6>DSRI LABS PVT.LTD. 
 
            </h6>
            <h6>RoadNo:04,PlotNo:21,IDAMallapur,IENacharam,Hyderabad-500076,TS </h6>
            <h6>9505831183, GST IN: 36AAKCD4650Q1Z1</h6>
           <h6> Website:	https://dsri.co.in/	Email: info@dsri.co.in</h6>
          </div>
          <div className='form-group centered-button-container'>
  <button onClick={handlePrint}>DOWNLOAD PDF</button>
</div>
</div>

      </div>
      </div>
  
  );
}


export default Formdata;

