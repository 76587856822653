import { Link } from 'react-router-dom';
import './Home.css';
import React, { useState } from 'react';
// import lg1 from "../assets/h1.png";
import lg2 from "../assets/hd.png";
// import backna from "../assets/flowern.jpg";

import image from "../assets/logo.jpeg";

import home1 from "../assets/globe.mp4";
// import lg from "../assets/lg.gif";
import home2 from "../assets/cauro2.gif";

// import frame from "../assets/Frame.png";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 const Home = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 2300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000, // Set the time in milliseconds for automatic sliding
  };
  const [selectedTab, setSelectedTab] = useState('home');

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    // Add logic to handle tab change, such as updating content or navigating to a different page
  };

  const [isVisible, setIsVisible] = useState(false);

  const handleImageLoad = () => {
    setIsVisible(true);
  };

  const containerStyle = {
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 6s ease-in-out',
  };
  const containerStyle2 = {
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 6s ease-in-out',
  };
  
  return (
    <div className='fullscreen'>
        {/* {isMobile ? <p>Mobile Content</p> : <p>Desktop Content</p>} */}
      <div className='sliderimg'>
      <h2></h2>
      <Slider {...settings}>
       

        <div className="image-with-text-container">
        <video autoPlay muted loop>
            <source src={home1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
      {/* <img src={h2} alt="Your Image" className="image" /> */}
       {/* <div className="text-container">
       <p></p>
    </div>  */}
        {/* <img src={home1} alt="image" width="100%" height="100%" /> */}
        </div>
        <div className='image-with-text-container1'>
        <img src={home2} alt="background"className="centered-imagev" width="800px" height="500px"/>
        </div>
       

      </Slider>
    </div>
       
    <nav id="nav1">
    <img src={image} alt="image" width="150px" height="100px" />
    <h1>DEFENSE SYSTEM RESEARCH INSTITUTION</h1>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/form">Form</Link></li>
        <li><Link to="/getpage">Get</Link></li>
        <li><Link to="/date">Date</Link></li>
       
      </ul>
     
    </nav>
    {/* <div className="center-container">
   <img src={lg2} className="centered-image" alt="Background" />
    </div> */}

    <nav className="navbar">
      <div className="navbar-content">
        <span className="navbar-text"><b>ABOUT DSRI</b></span>
      </div>
    </nav>
 <div className="head">
  <h4><b>DEFENSE SYSTEM RESEARCH INSTITUTION</b></h4>
 </div>
    <div className='vi2'>
 <div style={containerStyle}>
 <img src={lg2} 
 width="800px" height="500px" className="centered-imagev" alt="Background"
      
        onLoad={handleImageLoad}
      />  </div>
    <div className="defense">
   
      <p className="responsive-paragraph">
       
       "Defense System Research Institute" (DSRI). It's possible that such an organization has been established or gained prominence since then, but I don't have information on it.
 
 In general, defense research institutes are institutions or organizations dedicated to research and development in the field of defense. They might focus on various aspects such as technology development, strategic studies, policy analysis, or military tactics. If DSRI is a new or emerging entity, it could be involved in similar activities related to defense research and analysis.</p>
    
 
 
     </div></div>
 

      
    
    <div className="bottom-nav-bar">
        {/* <div className='contactus'> */}
        <div className='column'>
          <h5>CONTACT US</h5>
          
       <div className='pinkno'>
       
       <div className='pinkni'>
       <h1>DSRI</h1>
        <h5>DEFENSE SYSTEM  RESEARCH INSTITUTION </h5>
        <h4>Contact us: hello@dsri.co.in</h4>
       </div>
      
       </div>
       </div>
      
      <div className='links'>
      <h5>NAVIGATIONS</h5>
      
        <li><Link to="/">Home</Link></li>
        <li><Link to="/form">Form</Link></li>
        <li><Link to="/getpage">Get</Link></li>
      
       </div>
    
    </div>
    </div>
  
  )
}

export default Home
