import React from 'react';
import ReactDOM from "react-dom";
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import Home from "../src/Home/Home"
import Homee from "../src/Homee/Homee"
import Formdata from "../src/Formdata/Formdata"
import Form from "../src/Form/Form"
import Get from './get/get';
import Date from './Searchbydate/date';

import Searchbycompanyname from './searchbycompanyname/searchbycompanyname';

const Root = () => {

  return (
    
    

    <Router>
      
      <Routes>
        <Route path="/" exact element={<Homee />}/>
        <Route path="/Home" exact element={<Home />}/>
        <Route path="/form" element={<Form />} />
        <Route path="/formdata" element={<Formdata />} />
        <Route path="/app" element={<App />} />
        <Route path="/getpage" element={<Get />} />
        <Route path="/date" element={<Date />} />
        <Route path="/cdate" element={<Searchbycompanyname />} />


        {/* <Route path="/formcontext" element={<FormContext />} /> */}
        {/* <Route path="/app" element={<App />} />
        <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/Home" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/services" element={<Ser />} />
        <Route path="/careers" element={<Careers />} /> 
        <Route path="/contacts" element={<Contacts />} /> 
        <Route path="/courses" element={<Courses />} /> 
        <Route path="/registercourse" element={<Registercourse />} />
        <Route path="/logincourse" element={<Logincourse />} /> 
        <Route path="/payment" element={<Payment />} />  */}
      
         
        
      </Routes>
    </Router>
  );
};



ReactDOM.render(
  
<Root />, document.getElementById('root'));
