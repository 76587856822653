
import './Homee.css';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import image from "../assets/log1.png";
import Slider from 'react-slick';
import home1 from "../assets/globe.mp4";
import imagea from "../assets/hd.png";
import home2 from "../assets/cauro2.gif";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


// import videoSource1 from "../src/videos/kiavideo.mp4"
// import videoSource2 from "../src/videos/kiavideo2.mp4"

function Homee() {
  
  const settings = {
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Autoplay speed in milliseconds
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true, // Optional: Add pagination dots
  };

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  
  return (
    
    <div className="nb">
    <div className="video-container">
  <Slider {...settings}>
    <div className="slide-item">
      <video autoPlay muted loop>
        <source src={home1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="text-overlay">
        <h2>DEFENSE SYSTEM RESEARCH INSTITUTE</h2>
        <p>DSRI</p>
      </div>
    </div>
    <div className="slide-item">
      <img src={home2} alt="background" className="centered-imagev" />
      <div className="text-overlay">
        <h2>DEFENSE SYSTEM RESEARCH INSTITUTE</h2>
        <p>DSRI</p>
      </div>
    </div>
  </Slider>
</div>



<nav id="nav1">
        <div className="nav-container">
          <div className="nav-header">
          <img src={image} width="150px" height="100px" />
            <h1>DEFENSE SYSTEM RESEARCH INSTITUTE</h1>
            
            <div className="menu-toggle" onClick={toggleMenu}>
            
            <div className="hamburger"></div>
            <div className="hamburger"></div>
            <div className="hamburger"></div>
          </div>
          <h1 className='menuh1'>MENU</h1>
          </div>
          
        </div>
        <div id="menu-options" className={showMenu ? "show-menu" : ""}>
          <div className="menu-container">
          <ul>
                <li><Link to="/"><b>Home</b></Link></li><br></br>
                <li><Link to="/form"><b>Test Requisition Form</b></Link></li><br></br>
                <li><Link to="/getpage"><b>Search By Control Number</b></Link></li><br></br>
                <li><Link to="/date"><b>Search By Date Wise</b></Link></li><br></br>
                <li><Link to="/cdate"><b>Search By Company name Wise</b></Link></li><br></br>
            </ul>
          </div>
        </div>
      </nav>
    <div className='head2'>
         <h2>ABOUT DSRI</h2>
      
    </div>








{/* <div className='about'>

<div className='head1'>

        <h3><b>DEFENSE SYSTEM RESEARCH INSTITUTION</b></h3> 
       <p> "Defense System Research Institute" (DSRI). It's possible that such an organization has been established or gained prominence since then, but I don't have information on it.
 
 In general, defense research institutes are institutions or organizations dedicated to research and development in the field of defense. They might focus on various aspects such as technology development, strategic studies, policy analysis, or military tactics. If DSRI is a new or emerging entity, it could be involved in similar activities related to defense research and analysis.</p>
        </div>
        <div className='aboutimg'>
        <img src={imagea} alt="sc" />
        </div>
        </div> */}
        <div className='about'>
    <div className='about-content'>
        <div className='head1'>
            <h3><b>DEFENSE SYSTEM RESEARCH INSTITUTE</b></h3> 
            <p>"Defense System Research Institute" (DSRI). It's possible that such an organization has been established or gained prominence since then, but I don't have information on it.</p>
            <p>In general, defense research institutes are institutions or organizations dedicated to research and development in the field of defense. They might focus on various aspects such as technology development, strategic studies, policy analysis, or military tactics. If DSRI is a new or emerging entity, it could be involved in similar activities related to defense research and analysis.</p>
        </div>
    </div>
    <div className='aboutimg'>
        <img src={imagea} alt="sc" width="200px" height="400px" />
    </div>
</div>


        <footer className="footer">
  <nav id="nav2">
    <div className="footer-container">
      <div className="footer-section">
        <img src={image} alt="sc" width="150px" height="150px" />
      
          <h5>CONTACT US</h5>
      
       <h1>DSRI</h1>
        <h5>DEFENSE SYSTEM  RESEARCH INSTITUTE </h5>
        <h4>Contact us: hello@dsri.co.in</h4>
       </div>
      
     
       <div className='footer-section'>
       <div className='Buy'>
      <h5>NAVIGATIONS</h5>
      
      <li><Link to="/">Home</Link></li><br></br>
                <li><Link to="/form">Test Requisition Form</Link></li><br></br>
                <li><Link to="/getpage">Search By Control Number</Link></li><br></br>
                <li><Link to="/date">Search By Date Wise</Link></li><br></br>
                <li><Link to="/cdate">Search By Company name Wise</Link></li><br></br>


       </div>
    
       </div>
      

     
    </div>
  </nav>
</footer>

            
          </div>
          
      

        );
}

export default Homee;