import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import "./date.css";

const Get = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const formatDate = (date) => {
    const formattedDate = new Date(date);
    return formattedDate.toISOString().slice(0, 10);
  };

  const fetchDataByDateRange = async () => {
    try {
      setLoading(true);
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      // const response = await fetch(`http://192.168.0.107:8000/get-data-by-date-range/?start_date=${formattedStartDate}&end_date=${formattedEndDate}`);
      const response = await fetch(`http://139.59.90.224:8000/get-data-by-date-range/?start_date=${formattedStartDate}&end_date=${formattedEndDate}`);
     
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();
      setData(responseData.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
      toast.error("Failed to fetch data");
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchDataByDateRange();
  };

  return (
    <div className="excel-container">
       <div>
     <li><Link to="/">BACK</Link></li></div>
      <h2>SEARCH BY DATE</h2>
      <div className="search-container">
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          placeholder="Start Date"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          placeholder="End Date"
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : data.length === 0 ? (
        <div>No data available.</div>
      ) : (
        <table className="data-table">
          <thead>
            <tr>
              <th>S.No</th>
              {Object.keys(data[0]).map((fieldName) => (
                <th key={fieldName}>{fieldName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                {Object.values(row).map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Get;
