import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Form.css'; // Import the CSS file

function Form() {
  const navigate = useNavigate();
 
//frontend values
  const [formData, setFormData] = useState({
    controlnumber: '',
    issuenumber: '',
    issue_date: '',
    uid: '',
    revise_no: '',
    revise_date: '',
    date: '',
    projectUnit: '',
    Unit_Under_Test:'',
    si_no: '',
    Qty: '',
    unit_weight: '',
    fixture_weight: '',
    contact_person: '',
    mobile_no: '',
    company_name: '',
    environment_test:[],
    noise_vibration_measurement_tests: [],
    fluid_flow_test: [],
    test_standards: '',
    test_specifications: '',
     uncertainity_statement_in_test_report:'',
    statement_of_confirmity: '',
    decesion_rule_communicated_with_customers:[],
    conformance: '',
    specify: [],
    if_yes_pls_specify: '',
    record_of_change_request: '',
    requested_by: '',
    inspectedBySignature: '',
    customerSignature: '',
    test_start_date:'',
    uut_receipt_date:'',
    test_completion_date:'',
    test_equipment:'',
    remarks:'',
    requesition_accepted:'',
    unit_condition:'',
    wheather_repeat_test:'',
    remarks_if_any:'',
  });
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData((prevData) => ({
      ...prevData,
      issue_date: date
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleRadio = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
  
    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  };
 

  const environmentalTestsList = [
    'Vibration',
    'Shock',
    'Ship motion',
    'Acceleration',
    'Bump',
    'Tilt',
    'Low temperature',
    'High Temperature',
    'Burn in',
    'Damp Heat',
    'Thermal Shock',
    'Thermal Cycling',
    'Tropical Exposure',
    'Mould Growth',
    'Salt spray',
    'Drip Test',
    'Sand & Dust test (IPX5 &IPX6)',
    'Driving rain test(IP1X, IP2X, IP5X, IP6X,IP7X& IP8X)'
  ];
  
const specifyList=[
 'As per standard requirement' ,
 'As per customer requirement',
 'No external physical abnormalities found in EUT',
 'EUT is in Switched on Condition',
 'Others',
];
  const noiseVibrationTestsList = [
    'Structure Borne Noise',
    'Air Borne Noise'
  ];
 
  const decesion_rule_communicated_with_customerslist = [
    'Yes',
    'No'
  ];
  
 const   uncertainity_statement_in_test_reportlist=[
  'Required',
  'Not Required'
 ]
 const record_of_change_requestlist=[
  'Yes',
  'No',
 ]
//  const statementOfConformityLists=[
//   'Yes',
//   'No',
//  ];
//  const decisionRuleAgreedOptions=[
//   'Yes',
//   'No',
//  ];
 
  const fluidFlowTestsList = [
    'Burst Test',
    'Pneumatic Test',
    'Dismantling & Reassembly',
    'Life Cycle Test',
    'Flow Coefficient Hydraulic Test',
    'Hydraulic Proof',
    'Other'
  ];
  const requesition_acceptedlist= [
  'Yes',
  'No',
  ];
  const  wheather_repeat_testlist= [
    'Yes',
    'No',
    ];
    const  statement_of_confirmitylist= [
      'Yes',
      'No',
      ];

     
      
    
     
    
  const handleChan = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        environment_test: [...prevState.environment_test, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        environment_test: prevState.environment_test.filter(test => test !== value)
      }));
    }
  };
 


  const handleC = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        noise_vibration_measurement_tests: [...prevState.noise_vibration_measurement_tests, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        noise_vibration_measurement_tests: prevState.noise_vibration_measurement_tests.filter(test => test !== value)
      }));
    }
  };
  



  const handlespecify = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        specify: [...prevState.specify, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        specify: prevState.specify.filter(test => test !== value)
      }));
    }
  };


 


  const handlefluid= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        fluid_flow_test: [...prevState.fluid_flow_test, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        fluid_flow_test: prevState.fluid_flow_test.filter(test => test !== value)
      }));
    }
  };
  // jkhgytryouiopolikgjfht
  const handledecision= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        decesion_rule_communicated_with_customers: [...prevState.decesion_rule_communicated_with_customers, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        decesion_rule_communicated_with_customers: prevState.decesion_rule_communicated_with_customers.filter(test => test !== value)
      }));
    }
  };
 
  const handlereport= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        uncertainity_statement_in_test_report: [...prevState.uncertainity_statement_in_test_report, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        uncertainity_statement_in_test_report: prevState.uncertainity_statement_in_test_report.filter(test => test !== value)
      }));
    }
  };
  const handlerequest= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        record_of_change_request: [...prevState.record_of_change_request, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        record_of_change_request: prevState.record_of_change_request.filter(test => test !== value)
      }));
    }
  };
 
  const handlerepeat= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        wheather_repeat_test: [...prevState. wheather_repeat_test, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        wheather_repeat_test: prevState. wheather_repeat_test.filter(test => test !== value)
      }));
    }
  };

  const handleconformity= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        statement_of_confirmity: [...prevState. statement_of_confirmity, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        statement_of_confirmity: prevState. statement_of_confirmity.filter(test => test !== value)
      }));
    }
  };


  const handleaccepted= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        requesition_accepted: [...prevState.requesition_accepted, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        requesition_accepted:prevState. requesition_accepted.filter(test => test !== value)
      }));
    }
  };
  

  

 
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   navigate('/formdata', { state: formData });
  // };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     navigate('/formdata', { state: formData });
//     try {
//         // const response = await axios.post('http://192.168.0.103:8000/submit-test-requisition/', formData);
//         const response = await axios.post('http://192.168.0.167:8000/submit-test-requisition/', formData);
//         console.log(response.data); // Log the response data
//         // Handle success
//     } catch (error) {
//         console.error('Error:', error); // Log any errors
//         // Handle error
//     }
// };
// const handleSubmit = async (e) => {
//   e.preventDefault();


//   navigate('/formdata', { state: formData });

//   try {
   
//       const response = await axios.post('http://192.168.1.34:8000/submit-test-requisition/', formData);
//       console.log(response.data); 
    
//   } catch (error) {
//       console.error('Error:', error);
      
//   }
// };
const [error, setError] = useState('');
const [errori, setErrori] = useState('');
const [erroruid, setErroruid] = useState('');
const [errorproject, setErrorproject] = useState('');
const [errorunit, setErrorunit] = useState('');
const [errorsino, setErrorsino] = useState('');
const [errorfw, setErrorfw] = useState('');
const [errorreviseno, setErrorreviseno] = useState('');
const [errorq, setErrorq] = useState('');
const [errorp, setErrorp] = useState('');
const [erroruw, setErroruw] = useState('');
const [formError, setFormError] = useState(''); // Renamed to formError to avoid naming conflict

const [selectedrDate, setSelectedrDate] = useState(null);

const handlerDateChange = (date) => {
  setSelectedrDate(date);
  setFormData((prevData) => ({
    ...prevData,
    revise_date: date
  }));
};






const [selectedtest_completion_date, setSelectedtest_completion_date] = useState(null);

const handletest_completion_date = (date) => {
  setSelectedtest_completion_date(date);
  setFormData((prevData) => ({
    ...prevData,
    test_completion_date: date
  }));
};

const [selecteduut_receipt_date, setSelecteduut_receipt_date] = useState(null);

const handleuut_receipt_date = (date) => {
  setSelecteduut_receipt_date(date);
  setFormData((prevData) => ({
    ...prevData,
    uut_receipt_date: date
  }));
};
const [selectedtest_start_date, setSelectedtest_start_date] = useState(null);

const handletest_start_date = (date) => {
  setSelectedtest_start_date(date);
  setFormData((prevData) => ({
    ...prevData,
    test_start_date: date
  }));
};
const [selectedDated, setSelectedDated] = useState(null);

const handleChangeDated = (date) => {
  setSelectedDated(date);
  setFormData((prevData) => ({
    ...prevData,
    date: date
  }));
};

const handleControlChange = (event) => {

  const { name, value } = event.target;
  // const isValid = name !== 'controlnumber' || /^\d{0,10}$/.test(value);
  const isValid = name !== 'controlnumber' || /^[a-zA-Z0-9]{0,10}$/.test(value);


  if (isValid) {
    setFormData({ ...formData, [name]: value });
    setError('');
  } else {
    setError('Control number must be 10 numbers or less.');
  }
};

const handlesino = (event) => {

  const { name, value } = event.target;
  const isValidsino = name !== 'si_no' || /^\d{0,10}$/.test(value);

  if (isValidsino) {
    setFormData({ ...formData, [name]: value });
    setErrorsino('');
  } else {
    setErrorsino('serial number must be 10 digits or less.');
  }
};

const handleChangeissue = (event) => {

  const { name, value } = event.target;
  const isValidi = name !== 'issuenumber' || /^\d{0,8}$/.test(value);

  if (isValidi) {
    setFormData({ ...formData, [name]: value });
    setErrori('');
  } else {
    setErrori('Issue number must be 8 digits or less.');
  }
};
const handleChangeuid = (event) => {

  const { name, value } = event.target;
  const isValiduid = name !== 'uid' || /^\d{0,5}$/.test(value);

  if (isValiduid) {
    setFormData({ ...formData, [name]: value });
    setErroruid('');
  } else {
    setErroruid('uid number must be 5 digits or less.');
  }
};

const handleChangereviseno = (event) => {

  const { name, value } = event.target;
  const isValidreviseno = name !== 'revise_no' || /^\d{0,8}$/.test(value);

  if (isValidreviseno) {
    setFormData({ ...formData, [name]: value });
    setErrorreviseno('');
  } else {
    setErrorreviseno('revise number must be 8 digits or less.');
  }
};

const handleChangeproject = (event) => {

  const { name, value } = event.target;
  const isValidproject = name !== 'project' || /^\d{0,8}$/.test(value);

  if (isValidproject) {
    setFormData({ ...formData, [name]: value });
    setErrorproject('');
  } else {
    setErrorproject('project number must be 8 digits or less.');
  }
};
const handleunit= (event) => {

  const { name, value } = event.target;
  const isValidunit = name !== 'Unit_Under_Test' || /^\d{0,8}$/.test(value);

  if (isValidunit) {
    setFormData({ ...formData, [name]: value });
    setErrorunit('');
  } else {
    setErrorunit('unit under test number must be 8 digits or less.');
  }
};
// const handleAnotherFieldChange = (event) => {
//   const { name, value } = event.target;
//   setFormData({ ...formData, [name]: value });
// };
const handlequanity= (event) => {

  const { name, value } = event.target;
  const isValidq= name !== 'Qty' || /^\d{0,6}$/.test(value);

  if (isValidq) {
    setFormData({ ...formData, [name]: value });
    setErrorq('');
  } else {
    setErrorq('Quantity number must be 6 digits or less.');
  }
};
const handleuw= (event) => {

  const { name, value } = event.target;
  const isValiduw= name !== 'unit_weight' || /^\d{0,6}$/.test(value);

  if (isValiduw) {
    setFormData({ ...formData, [name]: value });
    setErroruw('');
  } else {
    setErroruw('unit weight number must be 6 digits or less.');
  }
};
const handlefw= (event) => {

  const { name, value } = event.target;
  const isValidfw= name !== 'fixture_weight' || /^\d{0,6}$/.test(value);

  if (isValidfw) {
    setFormData({ ...formData, [name]: value });
    setErrorfw('');
  } else {
    setErrorfw('fixture weight number must be 6 digits or less.');
  }
};
const handleSubmit = async (e) => {
  e.preventDefault(); // Prevent the default form submission behavior
  
  // Check if any required fields are empty
  const requiredFields = ['controlnumber', 'issuenumber', 'issue_date', ];
  const emptyFields = requiredFields.filter(field => !formData[field]);
  
  // If there are empty required fields, display a toast notification
  if (emptyFields.length > 0) {
    toast.error("Please fill control number ,issue number ,issue date fields.");
    return;
  }

  // Navigate to '/formdata' with the form data as state
  navigate('/formdata', { state: formData });

  try {
      // Send a POST request to the server endpoint with the form data
      // const response = await axios.post('http://192.168.0.107:8000//submit-test-requisition/', formData);
      const response = await axios.post('http://139.59.90.224:8000/submit-test-requisition/', formData);
      console.log(response.data); // Log the response data
      // Handle success
  } catch (error) {
      console.error('Error:', error); // Log any errors
      // Handle error
  }
};


const handlephone = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value
  }));

  if (name === 'mobile_no') {
    validatePhoneNumber(value);
  }
};

const validatePhoneNumber = (number) => {
  const phoneNumberPattern = /^\d{10}$/;
  if (!phoneNumberPattern.test(number)) {
    setErrorp((prevErrors) => ({
      ...prevErrors,
      mobile_no: 'Phone number must be exactly 10 digits.'
    }));
  } else {
    setErrorp((prevErrors) => ({
      ...prevErrors,
      mobile_no: ''
    }));
  }
};

  return (
  
    
    <div className="form-container">
      
      <div>
     <li><Link to="/">BACK</Link></li></div>
      <h2 className="form-title">TEST REQUISITION FORM</h2>
      <div className='containercontr'>
      <div className='form-group'>
      
        <label htmlFor='controlnumber'>Control No</label>
        {/* <input
          type='text'
          id='controlnumber'
          name='controlnumber'
          value={formData.controlnumber}
          // onChange={handleChange}
          onChange={handleChange}requiredFields
        /> */}
        
         <input
          type="text"
          name="controlnumber"
          value={formData.controlnumber}
          onChange={handleControlChange}
        /> 
        {error && <p className="error-message">{error}</p>}
      </div>
      
     
     
      {/* <input
  type='text'
  id='controlNo'
  name='controlNo'
  value={controlNo}
  onChange={(e) => setControlNo(e.target.value)}
/> */}
      <div className='form-group'>
         <label htmlFor='issuenumber'>Issue No</label>
         <input
           type='text'
           id='issuenumber'
           name='issuenumber'
           value={formData.issuenumber}
         onChange={handleChangeissue}
         
         />
           {errori && <p className="error-message">{errori}</p>}
      </div>  

             {/* <input
          type='text'
          id='issue_date'
          name='issue_date'
          value={formData.issue_date}
          onChange={handleChange}
        /> */}
  <div className='form-group'>
        <label htmlFor='revise_no'>Revise No</label>
        {/* value={formData.revise_no} */}
        <input
           type='text'
           id='revise_no'
           name='revise_no'
           value={formData.revise_no}
         onChange={handleChangereviseno}
         
         />
           {errorreviseno && <p className="error-message">{errorreviseno}</p>}
      </div>
      
      <div className='form-group'>
        <label htmlFor='uid'>UID</label>
        <input
           type='text'
           id='uid'
           name='uid'
           value={formData.uid}
         onChange={handleChangeuid}
         
         />
           {erroruid && <p className="error-message">{erroruid}</p>}
      </div>
    
      <div className='form-group'>
        <label htmlFor='issue_date'>Issue Date</label>
     
         <DatePicker
          id='issue_date'
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="yyyy/MM/dd"
       
        />

      </div>
      <div className='form-group'>
        <label htmlFor='revise_date'>Revise Date</label>
      
        
          {/* name='revise_date' */}
          <DatePicker
          id='revise_date'
          selected={selectedrDate}
          onChange={handlerDateChange}
          dateFormat="yyyy/MM/dd"
        />
      
      </div>
      <div className='form-group'>
        <label htmlFor='date'>Date</label>
        <DatePicker
          id='date'
          selected={selectedDated}
          onChange={handleChangeDated}
          dateFormat="yyyy/MM/dd"
          
        />
      </div>
    </div>
     
      <form onSubmit={handleSubmit}>
        <div className="containercontr">
        <div className="form-group">
          <label htmlFor="project" className="form-label">Project Unit</label>
          {/* <input type="text" id="project" name="project" value={formData.project} onChange={handleChange} className="form-input" /> */}
          <input
           type='text'
           id='project'
           name='project'
           value={formData.project}
         onChange={handleChangeproject}
         
         />
           {errorproject && <p className="error-message">{errorproject}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="Unit_Under_Test" className="form-label">Unit under test</label>
          {/* <input type="text" id="Unit_Under_Test" name="Unit_Under_Test" value={formData.Unit_Under_Test} onChange={handleChange} className="form-input" /> */}
          <input
           type='text'
           id='Unit_Under_Test'
           name='Unit_Under_Test'
           value={formData.Unit_Under_Test}
         onChange={handleunit}
         
         />
           {errorunit && <p className="error-message">{errorunit}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="si_no" className="form-label">Sl. No.</label>
          <input
           type='text'
           id='si_no'
           name='si_no'
           value={formData.si_no}
         onChange={handlesino}
         
         />
           {errorsino && <p className="error-message">{errorsino}</p>}
          
          {/* <input type="text" id="si_no" name="si_no" value={formData.si_no} onChange={handleChange} className="form-input" /> */}
        
        </div>
        <div className="form-group">       
            <label htmlFor="Qty" className="form-label">Quantity</label>
           {/* <input type="text" id="Qty" name="Qty" value={formData.Qty} onChange={handleChange} className="form-input" />         */}
           <input
           type='text'
           id='Qty'
           name='Qty'
           value={formData.Qty}
         onChange={handlequanity}
         
         />
           {errorq && <p className="error-message">{errorq}</p>}
           </div>
           <div className="form-group">
           <label htmlFor="unit_weight" className='form-label'>Unit Weight (kg)</label>
           
           {/* <input type="text" id="unit_weight" name="unit_weight" value={formData.unit_weight} onChange={handleChange} className="form-input"/> */}
           <input
           type='text'
           id='unit_weight'
           name='unit_weight'
           value={formData.unit_weight}
         onChange={handleuw}
         
         />
           {erroruw && <p className="error-message">{erroruw}</p>}
         </div>
         <div className="form-group">
          <label htmlFor="fixture_weight" className="form-label">Fixture Weight</label>
          
          {/* <input type="text" id="fixture_weight" name="fixture_weight" value={formData.fixture_weight} onChange={handleChange} className="form-input" /> */}
          <input
           type='text'
           id='fixture_weight'
           name='fixture_weight'
           value={formData.fixture_weight}
         onChange={handlefw}
         
         />
           {errorfw && <p className="error-message">{errorfw}</p>}
          
        </div>
        <div className="form-group">
           <label htmlFor="contact_person" className="form-label">Contact Person</label>
          <input type="text" id="contact_person" name="contact_person" value={formData.contact_person} onChange={handleChange} className="form-input" />
        </div>
         <div className="form-group">
           <label htmlFor="mobile_no" className="form-label">Phone Number</label>
           {/* <input type="text" id="mobile_no" name="mobile_no" value={formData.mobile_no} onChange={handleChange} className="form-input" /> */}
           <input
          type="text"
          id="mobile_no"
          name="mobile_no"
          value={formData.mobile_no}
          onChange={handlephone}
          className="form-input"
        />
        {errorp.mobile_no && <span className="error-message">{errorp.mobile_no}</span>}
         </div>
         <div className="form-group">
           <label htmlFor="company_name" className="form-label">Company Name</label>
           <input type="text" id="company_name" name="company_name" value={formData.company_name} onChange={handleChange} className="form-input" />
        </div>
        </div>
        {/* Add other input fields */}
 
        <div>
        <h2 className="select-tests-header">NAME OF THE TEST</h2>
        </div>
        <div className="containercontri">
<div>
      <h2> Environmental Test</h2>
      <form onSubmit={handleSubmit}>
        {environmentalTestsList.map(test => (
          <div key={test}>
            <label htmlFor={test}>
              <input type="checkbox" id={test} name={test} value={test} onChange={handleChan} />
              {test}
            </label>
          </div>
        ))}
      
      </form>
    </div>


    
    <div>
  <h2> Noise and Vibration Measurement Test</h2>
  <form onSubmit={handleSubmit}>
    {noiseVibrationTestsList.map(test => (
      <div key={test}>
        <label htmlFor={test}>
          <input type="checkbox" id={test} name={test} value={test} onChange={handleC} />
          {test}
        </label>
      </div>
    ))}
  </form>
</div>


<div>
  <h2>FLUID FLOW TEST   </h2>
  <form onSubmit={handleSubmit}>
    {fluidFlowTestsList.map(test => (
      <div key={test}>
        <label htmlFor={test}>
          <input type="checkbox" id={test} name={test} value={test} onChange={handlefluid} />
          {test}
        </label>
      </div>
    ))}
  </form>
</div>
</div>
<div className="containercontr">
<div className="form-group">
  <label htmlFor="test_standards" className="form-label">Test Standards</label>
  <textarea 
    id="test_standards" 
    name="test_standards" 
    value={formData.test_standards} 
    onChange={handleChange} 
    className="form-inputi" 
  />
</div>

{/* <div className="form-group">
  <label htmlFor="testStandards" className="form-label">Test Standards</label>
  <textarea id="testStandards" name="testStandards" value={formData.testStandards} onChange={handleChange} className="form-textarea" />
</div> */}


<div className="form-group">
  <label htmlFor="test_specifications" className="form-label">Test Specifications</label>
  <textarea 
    id="test_specifications" 
    name="test_specifications" 
    value={formData.test_specifications} 
    onChange={handleChange} 
    className="form-inputi" 
  />
</div>

</div>

{/* <div>
      <h2>Uncertainty Statement to be stated in Test Report </h2>
      <label>
        <input type="radio" name="uncertainity_statement_in_test_report" value="Yes" />
        Yes
      </label>
      <label>
        <input type="radio" name="uncertainity_statement_in_test_report" value="No" />
        No
      </label>
      <formData />
    </div> */}
<div className="containercontr">
<div>
      <h2>Decesion Rule Communicated with Customers </h2>
      <form onSubmit={handleSubmit}>
        {decesion_rule_communicated_with_customerslist.map(test => (
          <div key={test}>
            <label htmlFor={test}>
              <input type="checkbox" id={test} name={test} value={test} onChange={handledecision} />
              {test}
            </label>
          </div>
          
          
        ))}
      
      </form>
    </div>





    <div>
      <h2>Uncertainty Statement to be stated in Test Report </h2>
      <form onSubmit={handleSubmit}>
        {uncertainity_statement_in_test_reportlist.map(test => (
          <div key={test}>
            <label htmlFor={test}>
              <input type="checkbox" id={test} name={test} value={test} onChange={handlereport} />
              {test}
            </label>
          </div>
          
          
        ))}
      
      </form>
    </div>


    <div>
  <h2> Noise and Vibration Measurement Test</h2>
  <form onSubmit={handleSubmit}>
    {noiseVibrationTestsList.map(test => (
      <div key={test}>
        <label htmlFor={test}>
          <input type="checkbox" id={test} name={test} value={test} onChange={handleC} />
          {test}
        </label>
      </div>
    ))}
  </form>
</div>




    
      {/* <h2>Mention the Statement of conformity </h2> */}
      
      <div>
  <h2> Mention the Statement of Conformity </h2>
  <form onSubmit={handleSubmit}>
    {statement_of_confirmitylist.map(test => (
      <div key={test}>
        <label htmlFor={test}>
          <input type="checkbox" id={test} name={test} value={test} onChange={handleconformity} />
          {test}
        </label>
      </div>
    ))}
  </form>
</div>
</div>
    {/* <h2>Decision Rule (Communicated to and agreed with the customer)</h2> */}
    
   
       
        <div className="form-group">
          <label htmlFor="conformance" className="form-label">Conformance</label>
          <select id="conformance" name="conformance" value={formData.conformance} onChange={handleChange} className="form-input">
            <option value="Case a">Case a :Conformance</option>
            <option value="Case b">Case b: Conditional Conformance </option>
            <option value="Case c">Case c : Conditional Non-Conformance </option>
            <option value="Case d">Case d : Non-Conformance</option>
          </select>
        </div>
        <div>
       <h5> If conformity statement required, the customer has to specify in the test request.</h5></div>
       <div className="containercontr">
       <div>
  <h2>If Yes, please specify </h2>
  <form onSubmit={handleSubmit}>
    {specifyList.map(test => (
      <div key={test}>
        <label htmlFor={test}>
          <input type="checkbox" id={test} name={test} value={test} onChange={handlespecify} />
          {test}
        </label>
      </div>
    ))}
  </form>
</div>


     {/* <h2>Record of Change Request(s)(if any) </h2> */}
<div>
     <h2>Record of Change Request(s)(if any)</h2>
  <form onSubmit={handleSubmit}>
    {record_of_change_requestlist.map(test => (
      <div key={test}>
        <label htmlFor={test}>
          <input type="checkbox" id={test} name={test} value={test} onChange={handlerequest} />
          {test}
        </label>
      </div>
    ))}
   
  </form>
  
</div>
</div>
<div className="containercontr">
    <div className="form-group">
          <label htmlFor="if_yes_pls_specify" className="form-label">If yes, please specify	</label>
          <input type="text" id="if_yes_pls_specify" name="if_yes_pls_specify" value={formData.if_yes_pls_specify} onChange={handleChange} className="form-input" />
        </div>
      
        <div className="form-group">
          <label htmlFor="requested_by" className="form-label">Requested By</label>
          <input type="text" id="requested_by" name="requested_by" value={formData.requested_by} onChange={handleChange} className="form-input" />
        </div>
        </div>
        {/* <div className="form-group">
          <label htmlFor="inspectedBySignature" className="form-label">Signature (Inspected By)</label>
          <input type="text" id="inspectedBySignature" name="inspectedBySignature" value={formData.inspectedBySignature} onChange={handleChange} className="form-input" />
        </div>
        <div className="form-group">
          <label htmlFor="customerSignature" className="form-label">Signature (Customer)</label>
          <input type="text" id="customerSignature" name="customerSignature" value={formData.customerSignature} onChange={handleChange} className="form-input" />
        </div> */}
        </form>
        <h1>TO BE FILLED BY DSRI</h1>
        <form onSubmit={handleSubmit}>
<div className="containercontr">
        <div className="form-group">
          <label htmlFor="test_start_date" className="form-label">Test Start Date</label>
          {/* <input type="text" id="test_start_date" name="test_start_date" value={formData.test_start_date} onChange={handleChange} className="form-input" />
           */}
           <DatePicker
          id='test_start_date'
          selected={selectedtest_start_date}
          onChange={handletest_start_date}
          dateFormat="yyyy/MM/dd"
        />
        </div>
        {/* <div className="form-group">
          <label htmlFor="Unit_Under_Test" className="form-label">Unit_Under_Test</label>
          <input type="text" id="Unit_Under_Test" name="Unit_Under_Test" value={formData.Unit_Under_Test} onChange={handleChange} className="form-input" />
        </div> */}
        <div className="form-group">
          <label htmlFor="uut_receipt_date" className="form-label">UUT Receipt Date</label>
          {/* <input type="text" id="uut_receipt_date" name="uut_receipt_date" value={formData.uut_receipt_date} onChange={handleChange} className="form-input" /> */}
          <DatePicker
          id='uut_receipt_date'
          selected={selecteduut_receipt_date}
          onChange={handleuut_receipt_date}
          dateFormat="yyyy/MM/dd"
        />
        </div>
        <div className="form-group">       
            <label htmlFor="test_completion_date" className="form-label">Test Completion Date</label>
           {/* <input type="text" id="test_completion_date" name="test_completion_date" value={formData.test_completion_date} onChange={handleChange} className="form-input" />       */}
           <DatePicker
          id='test_completion_date'
          selected={selectedtest_completion_date}
          onChange={handletest_completion_date}
          dateFormat="yyyy/MM/dd"
        />
           </div>

           <div className="form-group">
           <label htmlFor="test_equipment" className='form-label'>Test Equipment</label>
           <input type="text" id="test_equipment" name="test_equipment" value={formData.test_equipment} onChange={handleChange} className="form-input"/>
         </div>
         {/* <div className="form-group">
          <label htmlFor="remarks" className="form-label">remarks </label>
          <input type="text" id="remarks" name="remarks" value={formData.remarks} onChange={handleChange} className="form-input" />
        </div> */}
        

<div className="form-group">
           <label htmlFor="unit_condition" className="form-label">Unit Condition</label>
           <input type="text" id="unit_condition" name="unit_condition" value={formData.unit_condition} onChange={handleChange} className="form-input" />
        </div>
        <div className="form-group">
           <label htmlFor="total_time_of_equipment_use" className="form-label">Total Time of Equipment Use </label>
          <input type="text" id="total_time_of_equipment_use" name="total_time_of_equipment_use" value={formData.total_time_of_equipment_use} onChange={handleChange} className="form-input" />
        </div>



        <div>
      <h2>Requisition Accepted</h2>
      <form onSubmit={handleSubmit}>
        {requesition_acceptedlist.map(test => (
          <div key={test}>
            <label htmlFor={test}>
              <input type="checkbox" id={test} name={test} value={test} onChange={handleaccepted} />
              {test}
            </label>
          </div>
        ))}
      
      </form>
    </div>


      
      {/* <Whether Repeat test */}
      <div>

      <h2>Whether Repeat Test</h2>
      <form onSubmit={handleSubmit}>
        { wheather_repeat_testlist.map(test => (
          <div key={test}>
            <label htmlFor={test}>
              <input type="checkbox" id={test} name={test} value={test} onChange={handlerepeat} />
              {test}
            </label>
          </div>
        ))}
      
      </form>
    </div>

    <div className="form-group">
  <label htmlFor="remarks" className="form-label">Remarks</label>
  <textarea 
    id="remarks" 
    name="remarks" 
    value={formData.remarks} 
    onChange={handleChange} 
    className="form-inputi" 
  />
</div>
        <div className="form-group">
  <label htmlFor="remarks_if_any" className="form-label">Remarks if any</label>
  <textarea 
    id="remarks_if_any" 
    name="remarks_if_any" 
    value={formData.remarks_if_any} 
    onChange={handleChange} 
    className="form-inputi" 
  />
</div>
</div> 
<ToastContainer />
<button type="submit" className="form-button">Submit</button>
        </form>
        {/* <button type="submit" className="form-button" onClick={upload}>Submit</button> */}
       
     
{/* jlfhuahklrhihe */}

      
    </div>
  );
}

export default Form;










