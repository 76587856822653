import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import "./searchbycompanyname.css";

const Get = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
    //   const response = await fetch(`http://192.168.0.107:8000/get-data-by-company-name/?company_name=${searchValue}`);
    const response = await fetch(`http://139.59.90.224:8000/get-data-by-company-name/?company_name=${searchValue}`);
     
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();
      setData(responseData.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
      toast.error("Failed to fetch data");
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchData();
  };

  return (
    <div className="excel-container">
      <div>
        <li><Link to="/">BACK</Link></li>
      </div>
      <h2>SEARCH BY COMPANY NAME</h2>
      <div className="search-container">
        <input
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search by Company Name"
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : data.length === 0 ? (
        <div>No data available.</div>
      ) : (
        <table className="data-table">
          <thead>
            <tr>
              <th>S.No</th>
              {Object.keys(data[0]).map((fieldName) => (
                <th key={fieldName}>{fieldName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                {Object.values(row).map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Get;
