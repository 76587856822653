import React from 'react';
import Form from './Form/Form';

function App() {
  const handleSubmit = (formData) => {
    console.log('Form submitted with data:', formData);
    // Add logic to handle form submission (e.g., send data to server)
  };

  return (
    <div>
      <h1>Form Example</h1>
      <Form onSubmit={handleSubmit} />
    </div>
  );
}

export default App;
